<template>
  <!--#region Bayi Ayarları -->
  <b-col cols="12" md="6">
    <b-row class="options-container">
      <b-col cols="12" class="d-flex justify-content-md-end">
        <b-form-group
          :label="$t('company_crud.1.type')"
          label-for="company-is-type"
        >
          <b-form-radio-group
            id="company-is-type"
            v-model="type"
            :options="typeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-md-end">
        <b-form-group
          :label="$t('company_crud.1.is_passive')"
          label-for="company-is-passive"
        >
          <b-form-checkbox
            :checked="!company.is_passive"
            id="company-is-passive"
            switch
            inline
            @change="updateCompanyState"
          >
            {{
              company.is_passive
                ? $t("company_crud.1.passive")
                : $t("company_crud.1.active")
            }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-col>
  <!--#endregion -->
</template>

<style lang="scss" scoped>
.options-container {
  height: 100%;
  display: flex;
  align-content: space-around;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import {
  SET_COMPANY_STATE,
  SET_COMPANY_TYPE,
} from "@/store/services/company-service";

export default {
  name: "company-options",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
  },
  computed: {
    ...mapGetters(["company"]),
    type: {
      get() {
        return this.company.type;
      },
      set(val) {
        this.$store.commit(SET_COMPANY_TYPE, val);
      },
    },
    typeOptions() {
      return [
        { text: this.$t("company_crud.1.individual"), value: 1 },
        { text: this.$t("company_crud.1.corporate"), value: 2 },
      ];
    },
  },
  methods: {
    updateCompanyState(val) {
      this.$store.commit(SET_COMPANY_STATE, !val);
    },
  },
};
</script>