<template>
  <!--#region Bayi Telefon -->
  <b-col cols="12" md="4">
    <b-form-group :label="$t('address.phone')" label-for="phone">
      <validation-provider
        #default="{ errors }"
        :name="$t('address.phone')"
        rules="required|min:10|max:10"
      >
        <cleave
          id="phone"
          v-model="companyInvoice.phone"
          :options="phoneOptions"
          class="form-control"
          :class="{
            'is-invalid': errors.length > 0,
          }"
        />
        <b-form-invalid-feedback>
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import { BCol, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.tr.js";
import { mapGetters } from "vuex";

export default {
  name: "company-phone",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    Cleave,
  },
  data() {
    return {
      phoneOptions: {
        phone: true,
        phoneRegionCode: "TR",
      },
    };
  },
  computed: {
    ...mapGetters(["companyInvoice"]),
  },
};
</script>