<template>
  <!--#region Bayi Adres Satır 2 -->
  <b-col cols="12">
    <b-form-group
      :label="`${$t('address.line')} 2`"
      label-for="line2"
      class="mb-2"
    >
      <validation-provider
        #default="{ errors }"
        :name="`${$t('address.line')} 2`"
        rules="max:100"
      >
        <b-form-textarea
          id="line2"
          v-model="companyInvoice.address_line2"
          :state="errors.length > 0 ? false : null"
          rows="2"
          no-resize
        />
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import { mapGetters } from "vuex";

export default {
  name: "company-line2",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    Cleave,
  },
  computed: {
    ...mapGetters(["companyInvoice"]),
  },
};
</script>