<template>
  <!--#region Fiyat Liste ID -->
  <b-row v-if="companyPriceList">
    <b-col cols="12" class="d-flex">
      <b-form-group
        :label="$t('company_crud.3.price_list')"
        label-for="price-list"
        class="mr-2"
      >
        <b-card-text>{{ companyPriceList.group_name }}</b-card-text>
      </b-form-group>
      <b-form-group
        :label="$t('company_crud.3.rule_price')"
        label-for="rule-price"
      >
        <div class="d-flex" id="rule-price">
          <div
            class="process px-1 btn-outline-primary rounded-left cursor-pointer"
            :class="{ 'btn-primary': companyPriceList.rule_price }"
            @click="changeRulePrice(true)"
          >
            {{ $t("company_crud.3.process_type_true") }}
          </div>
          <div
            class="process px-1 btn-outline-primary rounded-right cursor-pointer"
            :class="{ 'btn-primary': !companyPriceList.rule_price }"
            @click="changeRulePrice(false)"
          >
            {{ $t("company_crud.3.process_type_false") }}
          </div>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
  <!--#endregion -->
</template>

<style lang="scss" scoped>
.process {
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
  BFormCheckbox,
  BCardText,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import {
  UPDATE_PRICE_LIST_RULE_PRICE,
} from "@/store/services/priceList-service";

export default {
  name: "company-price-list-select",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    BFormCheckbox,
    BCardText,
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapGetters([
      "companyPriceList",
      "companyPriceLists",
      "companyPriceListReady",
      "companyPriceListProducts",
    ]),
  },
  methods: {
    changeRulePrice(val) {
      this.$store.dispatch(UPDATE_PRICE_LIST_RULE_PRICE, val);
    },
  },
};
</script>
