<template>
  <!--#region Rapor Tanım -->
  <validation-observer ref="phase" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ $t("company_crud.3.title") }}</h5>
        <small class="text-muted">{{ $t("company_crud.3.placeholder") }}</small>
        <div class="next-prev-short">
          <a v-b-tooltip.hover.top="$t('company_crud.prev')" @click="prevTab">
            <feather-icon icon="ArrowLeftIcon" />
          </a>
          <a v-b-tooltip.hover.top="$t('company_crud.save')" @click="nextTab">
            <feather-icon icon="ArrowRightIcon" />
          </a>
        </div>
      </b-col>
      <b-col cols="12">
        <company-price-list-select />
      </b-col>
      <b-col cols="12">
        <company-price-list />
      </b-col>
    </b-row>
  </validation-observer>
  <!--#endregion -->
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { BRow, BCol, VBTooltip } from "bootstrap-vue";
import CompanyPriceListSelect from "./PriceListSelect.vue";
import CompanyPriceList from "./PriceList.vue";

export default {
  name: "company-phase-three",
  components: {
    ValidationObserver,
    BRow,
    BCol,

    CompanyPriceListSelect,
    CompanyPriceList,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    check() {
      return new Promise((resolve) => {
        this.$refs.phase.validate().then((response) => {
          resolve(response);
        });
      });
    },
    prevTab() {
      this.$root.$emit("prevTab");
    },
    nextTab() {
      this.$root.$emit("nextTab");
    },
  },
};
</script>