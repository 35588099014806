<template>
  <form-content :isNew="true" />
</template>

<script>
import FormContent from "./Phases/FormContent.vue";

export default {
  name: "company-create",
  components: {
    FormContent,
  },
};
</script>
