<template>
  <b-row>
    <b-col cols="12">
      <b-overlay :show="!companyPriceListReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
        <b-row v-if="companyPriceList">
          <b-col cols="12">
            <b-table responsive :items="companyPriceListProducts" :fields="fields" :per-page="perPage" :current-page="currentPage" class="price-list-table">
              <template #thead-top>
                <price-list-filters />
              </template>
              <template #cell(index)="data">
                {{ (currentPage - 1) * 20 + data.index + 1 }}
              </template>
              <template #cell(p_cat)="data">
                {{ data.item.p_cat }}
              </template>
              <template #cell(p_code)="data">
                <span v-b-tooltip.hover.v-primary.bottom.html="getTooltip(data.item)">
                  {{ data.item.p_code }}
                </span>
              </template>
              <template #cell(p_name)="data">
                <b-link
                  :to="{
                    name: '/Shop/Product',
                    params: { id: data.item.p_id }
                  }"
                  :id="'product-' + data.item.p_id"
                  target="_blank"
                >
                  {{ data.item.p_name }}
                </b-link>
                <b-popover :target="'product-' + data.item.p_id" placement="right" variant="primary" triggers="hover">
                  <product-tooltip :product="data.item" />
                </b-popover>
              </template>
              <template #cell(p_brand)="data">
                {{ data.item.p_brand }}
              </template>
              <template #cell(p_price)="data">
                <h6 class="text-bold mb-0">
                  {{ showPurePrice(data.item) }}
                </h6>
              </template>
              <template #cell(c_percentagle)="data">
                <b-input-group v-show="companyPriceList.rule_price">
                  <cleave v-model="data.item.c_percentagle_str" :options="amountOptions" class="form-control" :disabled="data.item.c_amount > 0" @input="priceListChangePercentagle(data.item)" />
                  <b-input-group-append is-text>%</b-input-group-append>
                </b-input-group>
                <div v-show="!companyPriceList.rule_price">
                  <h6
                    class="text-bold mb-0"
                    :class="{
                      'text-success': data.item.c_type_show === 1,
                      'text-danger': data.item.c_type_show === -1
                    }"
                    v-if="data.item.new_amount_show > 0"
                  >
                    {{ data.item.c_percentagle_show }}
                  </h6>
                  <feather-icon icon="MinusIcon" size="25" v-else />
                </div>
              </template>
              <template #cell(c_amount)="data">
                <b-input-group v-show="companyPriceList.rule_price">
                  <cleave v-model="data.item.c_amount_str" :options="amountOptions" class="form-control" :disabled="data.item.c_percentagle > 0" @input="priceListChangeAmount(data.item)" />
                  <b-input-group-append v-if="currenciesReady" is-text>
                    {{ getCurrencySymbol(data.item) }}
                  </b-input-group-append>
                </b-input-group>
                <div v-show="!companyPriceList.rule_price">
                  <span v-if="data.item.new_amount_show > 0">
                    <h6
                      class="text-bold mb-0"
                      :class="{
                        'text-success': data.item.c_type_show === 1,
                        'text-danger': data.item.c_type_show === -1
                      }"
                    >
                      {{ showDiffPrice(data.item) }}
                    </h6>
                  </span>
                  <feather-icon icon="MinusIcon" size="25" v-else />
                </div>
              </template>
              <template #cell(c_type)="data">
                <div v-if="companyPriceList.rule_price" class="d-flex">
                  <b-form-checkbox v-model="data.item.c_type" switch inline class="change-type-switch" @change="priceListChangeType(data.item)" />
                  <feather-icon
                    :class="{
                      'text-success': data.item.c_type,
                      'text-danger': !data.item.c_type
                    }"
                    :icon="`${data.item.c_type ? 'ChevronsUp' : 'ChevronsDown'}Icon`"
                    size="25"
                  />
                </div>
                <div v-else>
                  <feather-icon
                    :class="{
                      'text-success': data.item.c_type_show === 1,
                      'text-danger': data.item.c_type_show === -1
                    }"
                    :icon="`${data.item.c_type_show === 0 ? 'Minus' : data.item.c_type_show === 1 ? 'ChevronsUp' : 'ChevronsDown'}Icon`"
                    size="25"
                    v-if="data.item.new_amount_show > 0"
                  />
                  <feather-icon icon="MinusIcon" size="25" v-else />
                </div>
              </template>
              <template #cell(new_amount)="data">
                <b-input-group v-show="companyPriceList.rule_price">
                  <cleave v-model="data.item.new_amount" :options="amountOptions" class="form-control" disabled />
                  <b-input-group-append v-show="currenciesReady">
                    <b-dropdown class="currency-dropdown" :text="getCurrencySymbol(data.item)" variant="outline-primary" right>
                      <b-dropdown-item
                        v-for="cur in currencies"
                        :key="cur.currency_code"
                        :class="{
                          'bg-light-primary': cur.currency_id === data.item.currency_id
                        }"
                        @click="changeCurrency(data.item, cur)"
                      >
                        {{ cur.currency_code }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group v-show="!companyPriceList.rule_price">
                  <cleave v-model="data.item.new_amount_show" :options="amountOptions" class="form-control" @input="changeNewPrice(data.item)" />
                  <b-input-group-append v-show="currenciesReady">
                    <b-dropdown class="currency-dropdown" :text="getCurrencySymbol(data.item)" variant="outline-primary" right>
                      <b-dropdown-item
                        v-for="cur in currencies"
                        :key="cur.currency_code"
                        :class="{
                          'bg-light-primary': cur.currency_id === data.item.currency_id
                        }"
                        @click="changeCurrency(data.item, cur)"
                      >
                        {{ cur.currency_code }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-input-group-append>
                </b-input-group>
              </template>
              <template #cell(process)="data">
                <b-button variant="gradient-success" class="btn-icon rounded-circle" @click="changePrice(data.item)" size="sm">
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </template>
            </b-table>
            <b-card-text v-show="companyPriceListProducts.length === 0">
              {{ $t('shop.not_found') }}
            </b-card-text>
          </b-col>
          <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" class="d-flex justify-content-center align-items-center flex-column" v-if="companyPriceListReady">
            <h3 class="text-center" v-html="$t('company_crud.3.price_list_not_found')" />
            <b-button variant="success" class="mt-1" @click="setPriceList()">
              {{ $t('company_crud.3.set_price_list') }}
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<style lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';

.change-type-switch {
  padding: 0;
  margin: 0;
  .custom-control-label {
    padding-left: 2.5rem;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    width: 2.7rem;
    height: 1.7rem;
    border-color: $success !important;
    background-color: $success !important;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.05rem);
    width: 1.1rem;
    height: 1.1rem;
  }

  .custom-control-input:not(:checked) ~ .custom-control-label:before {
    width: 2.7rem;
    height: 1.7rem;
    border-color: $danger !important;
    background-color: $danger !important;
  }
}

.multiple-filters {
  height: 100px;
  max-height: 100px;
}

.product-group-filter {
  min-width: 10rem;
  border-collapse: separate;
}

.product-group-filter-lg {
  min-width: 11rem;
  border-collapse: separate;
}

.price-list-table {
  @include media-breakpoint-down(lg) {
    font-size: 75%;
    .product-category,
    .product-brand,
    .product-search-area {
      display: none;
    }
    .product-name-area {
      display: table-cell !important;
    }
    .product-name {
      min-width: 10rem;
    }
  }
  tbody,
  thead {
    tr {
      td,
      th {
        padding: 0.3rem 0.5rem;
      }
    }
  }
}
.product-name {
  min-width: 15rem;
  width: 100%;
}

.currency-dropdown {
  button {
    width: 3.5rem;
    padding: 0;
    padding-right: 0.4rem;
  }
}

.update-button {
  width: 38px;
  height: 38px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
  }
}
</style>

<script>
import { BRow, BCol, BPopover, BLink, BOverlay, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend, BPagination, BDropdown, BDropdownItem, BButton, VBTooltip, BCardText } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { mapGetters } from 'vuex'
import { convertNumbertoString, priceFormat } from '@/assets/js/functions/currency-functions'
import ProductTooltip from '../../../Order/Product/Tooltip.vue'
import PriceListFilters from './PriceListFilters.vue'
import { CHANGE_PRODUCT_PRICE, SET_COMPANY_PRICE_LIST } from '@/store/services/priceList-service'
import { GET_ALL_CURRENCY_TYPES } from '@/store/services/currency-service'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { priceListChangePercentagle, priceListChangeAmount, priceListChangeType } from '@/assets/js/functions/priceList-functions'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BPopover,
    BLink,
    BOverlay,
    BTable,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BCardText,
    Cleave,
    ProductTooltip,
    PriceListFilters,
    FeatherIcon
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      currencies: [],
      currenciesReady: false
    }
  },
  created() {
    this.$store.dispatch(GET_ALL_CURRENCY_TYPES).then(response => {
      this.currencies = response
      this.currenciesReady = true
    })
  },
  computed: {
    ...mapGetters(['getLocale', 'companyPriceList', 'companyPriceListProducts', 'companyPriceListReady']),
    totalRows() {
      return this.companyPriceListProducts.length
    },
    fields() {
      return [
        {
          key: 'index',
          label: 'N',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          key: 'p_cat',
          label: this.$t('transaction.p_cat'),
          tdClass: 'text-nowrap product-category',
          thClass: 'text-nowrap product-category'
        },
        {
          key: 'p_code',
          label: this.$t('transaction.p_code'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          key: 'p_name',
          label: this.$t('transaction.p_name'),
          tdClass: 'product-name',
          thClass: 'product-name'
        },
        {
          key: 'p_brand',
          label: this.$t('transaction.p_brand'),
          tdClass: 'product-brand',
          thClass: 'product-brand'
        },
        {
          key: 'p_price',
          label: this.$t('company_crud.3.p_price'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        },
        {
          key: 'c_percentagle',
          label: this.$t('company_crud.3.c_percentagle'),
          tdClass: 'product-group-filter',
          thClass: 'product-group-filter'
        },
        {
          key: 'c_amount',
          label: this.$t('company_crud.3.c_amount'),
          tdClass: 'product-group-filter',
          thClass: 'product-group-filter'
        },
        {
          key: 'c_type',
          label: this.$t('company_crud.3.c_type'),
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          key: 'new_amount',
          label: this.$t('company_crud.3.new_amount'),
          tdClass: 'text-nowrap product-group-filter-lg',
          thClass: 'text-nowrap product-group-filter-lg'
        },
        {
          key: 'process',
          label: ''
        }
      ]
    },
    amountOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  methods: {
    showPurePrice(product) {
      return priceFormat(product.p_price, this.getLocale, product.p_cur)
    },
    showDiffPrice(product) {
      return priceFormat(product.c_amount_show, this.getLocale, product.p_cur)
    },
    getCurrencySymbol(product) {
      return this.currencies.find(x => x.currency_id === product.currency_id)?.symbol
    },
    changeCurrency(product, currency) {
      let ref = this.companyPriceListProducts.find(x => x.p_id === product.p_id)
      if (ref) {
        ref.currency_id = currency.currency_id
        ref.p_cur = currency.currency_code
      }
    },
    changePrice(product) {
      if (product.p_price !== product.new_amount * 1 || product.p_price !== product.new_amount_show * 1) {
        let body = {
          price_list_d_id: product.price_list_d_id,
          currency_id: product.currency_id
        }
        if (!this.companyPriceList.rule_price) {
          body.rule_amount = 0
          body.rule_rate = 0
          body.rule_type = 0
          body.unit_price_tra = product.new_amount_show * 1
        } else {
          body.rule_amount = +product.c_amount
          body.rule_rate = +product.c_percentagle
          body.rule_type = product.c_type ? 1 : -1
          body.unit_price_tra = product.p_price
        }
        this.$store.dispatch(CHANGE_PRODUCT_PRICE, body).then(() => {
          if (!this.companyPriceList.rule_price) {
            product.p_price = product.new_amount_show * 1
            product.new_amount = product.new_amount_show * 1
            product.new_amount_show = 0
            product.c_amount_show = ''
            product.c_percentagle_show = ''
            product.c_type_show = 0
          }
        })
      }
    },
    changeNewPrice(product) {
      let temp = +product.new_amount_show

      if (temp > 0) {
        if (product.p_price > temp) {
          product.c_type_show = -1
        } else if (product.p_price < temp) {
          product.c_type_show = 1
        } else {
          product.c_type_show = 0
        }

        let per = temp / product.p_price - 1
        per = Math.abs(per) * 100
        product.c_percentagle_show = convertNumbertoString(per) + '%'

        let diff = Math.abs(temp - product.p_price)
        product.c_amount_show = diff
      }
    },
    setPriceList() {
      this.$store.dispatch(SET_COMPANY_PRICE_LIST, {
        price_list_m_id: 1,
        company_id: this.$route.params.id * 1
      })
    },
    getTooltip(product) {
      return `
      <table>
        <tr>
          <td class="text-nowrap text-left">${this.$t('company_crud.3.product_group')}</td>
          <td>:</td>
          <td class="pl-1">${product.p_cat}</td>
        </tr>
        <tr>
          <td colspan="3"><hr class="my-50 border-white" /></td>
        </tr>
        <tr>
          <td class="text-nowrap text-left">${this.$t('transaction.p_brand')}</td>
          <td>:</td>
          <td class="pl-1">${product.p_brand}</td>
        </tr>
      </table>`
    },
    priceListChangePercentagle,
    priceListChangeAmount,
    priceListChangeType
  }
}
</script>
