<template>
  <!--#region Bayi Kısa Adı -->
  <b-col cols="12" md="4">
    <b-form-group
      :label="$t('company_crud.1.trade_name')"
      label-for="company-trade-name"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('company_crud.1.trade_name')"
        rules="required|min:3"
      >
        <b-form-input
          id="company-trade-name"
          v-model="company.company_trade_name"
          :state="errors.length > 0 ? false : null"
          :placeholder="$t('company_crud.1.trade_name_placeholder')"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import { mapGetters } from "vuex";

export default {
  name: "company-trade-name",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
  },
  computed: {
    ...mapGetters(["company"]),
  },
};
</script>