<template>
  <!--#region Bayi Grubu -->
  <b-col cols="12" md="4">
    <b-form-group :label="$t('company_crud.1.group')" label-for="company-group">
      <validation-provider
        #default="{ errors }"
        :name="$t('company_crud.1.group')"
        rules="required"
      >
        <b-overlay
          :show="!companyGroupsReady"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <treeselect
            id="company-group"
            v-model="company.company_group_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="companyGroups"
            :placeholder="$t('company_crud.1.group_placeholder')"
            :class="errors.length > 0 ? 'is-invalid' : ''"
          />
        </b-overlay>
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";
import { mapGetters } from "vuex";
import { GET_COMPANY_GROUPS } from "@/store/services/company-service";

export default {
  name: "company-group",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    Treeselect,
  },
  computed: {
    ...mapGetters(["company", "companyGroups", "companyGroupsReady"]),
  },
  created() {
    this.$store.dispatch(GET_COMPANY_GROUPS);
  },
};
</script>