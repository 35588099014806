<template>
  <!--#region Bayi Genel Bilgiler -->
  <validation-observer ref="phase" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ $t("company_crud.1.title") }}</h5>
        <small class="text-muted">{{ $t("company_crud.1.placeholder") }}</small>
        <div class="next-prev-short">
          <a v-b-tooltip.hover.top="$t('company_crud.next')" @click="nextTab">
            <feather-icon icon="ArrowRightIcon" />
          </a>
        </div>
      </b-col>
      <company-photo />
      <company-options />
      <company-name />
      <company-trade-name />
      <company-code />
      <company-group />
      <company-tax-office />
      <company-tax-number v-if="company.type === 2" />
      <company-id-number v-else />
    </b-row>
  </validation-observer>
  <!--#endregion -->
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { BRow, BCol, VBTooltip } from "bootstrap-vue";
import CompanyPhoto from "./Photo.vue";
import CompanyOptions from "./Options.vue";
import CompanyName from "./Name.vue";
import CompanyTradeName from "./TradeName.vue";
import CompanyCode from "./Code.vue";
import CompanyGroup from "./Group.vue";
import CompanyTaxOffice from "./TaxOffice.vue";
import CompanyTaxNumber from "./TaxNo.vue";
import CompanyIdNumber from "./ID.vue";
import { mapGetters } from "vuex";

export default {
  name: "company-phase-one",
  components: {
    ValidationObserver,
    BRow,
    BCol,

    CompanyPhoto,
    CompanyOptions,
    CompanyName,
    CompanyTradeName,
    CompanyCode,
    CompanyGroup,
    CompanyTaxOffice,
    CompanyTaxNumber,
    CompanyIdNumber,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapGetters(["company"]),
  },
  methods: {
    check() {
      return new Promise((resolve) => {
        this.$refs.phase.validate().then((response) => {
          resolve(response);
        });
      });
    },
    nextTab() {
      this.$root.$emit("nextTab");
    },
  },
};
</script>