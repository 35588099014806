<template>
  <!--#region Ülke -->
  <b-col cols="12" md="3">
    <b-form-group
      :label="$t('address.town')"
      label-for="address-town"
      v-show="companyInvoice.city_id"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('address.town')"
        rules="required"
      >
        <b-overlay
          :show="!companyInvoice.town_ready"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <treeselect
            id="address-town"
            v-model="companyInvoice.town_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="towns"
            :class="errors.length > 0 ? 'is-invalid' : ''"
          />
        </b-overlay>
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";
import { mapGetters } from "vuex";

export default {
  name: "company-town",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    Treeselect,
  },
  computed: {
    ...mapGetters(["companyInvoice", "towns"]),
  },
};
</script>