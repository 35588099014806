<template>
  <!--#region Bayi Resmi -->
  <b-col cols="12" md="6">
    <h3 class="mb-1">{{ $t("company_crud.1.photo") }}</h3>
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="companyPhoto"
          :src="
            company.image_base_64 != null
              ? company.image_base_64
              : require('@/assets/images/avatars/company_default.png')
          "
          :text="$t('company_crud.1.photo')"
          size="125px"
          variant="light-primary"
          badge
          :badge-variant="company.is_passive ? 'danger' : 'success'"
          class="company-photo"
        />
      </template>
      <div class="photo-button-container">
        <div class="photo-button-div">
          <b-button variant="primary" block @click="selectPhoto()">
            <b-form-file
              ref="pickPhoto"
              accept="image/*"
              class="d-none"
              v-model="selectedPhoto"
            />
            <span class="d-none d-sm-inline">
              {{ $t("company_crud.1.photo_update") }}
            </span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
        </div>
        <div class="photo-button-div">
          <b-button variant="outline-secondary" block @click="deletePhoto()">
            <span class="d-none d-sm-inline">
              {{ $t("company_crud.1.photo_delete") }}
            </span>
            <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
          </b-button>
        </div>
      </div>
    </b-media>
  </b-col>
  <!--#endregion -->
</template>

<style lang="scss">
.company-photo {
  border-radius: 50% !important;
  .b-avatar-badge {
    border: 2px solid white;
    min-width: 1em;
    min-height: 1em;
    bottom: 0px !important;
    right: 0px !important;
  }
}
.photo-button-container {
  display: flex;
  flex-direction: column;
  height: 125px;
  justify-content: space-around;
  .photo-button-div {
    width: 170px;
  }
}
</style>

<script>
import { BCol, BMedia, BAvatar, BButton, BFormFile } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { SET_NOTIFICATION } from "@/store/services/module-service";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "company-photo",
  components: {
    BCol,
    BMedia,
    BAvatar,
    BButton,
    BFormFile,
  },
  data() {
    return {
      selectedPhoto: null,
    };
  },
  computed: {
    ...mapGetters(["company"]),
  },
  methods: {
    selectPhoto() {
      this.$refs.pickPhoto.$el.childNodes[0].click();
    },
    deletePhoto() {
      this.company.image_base_64 = null;
    },
  },
  watch: {
    selectedPhoto(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          if (newValue.size < 128 * 1024) {
            // 128KB
            base64Encode(newValue)
              .then((value) => {
                this.company.image_base_64 = value;
              })
              .catch(() => {
                this.company.image_base_64 = null;
              });
          } else {
            this.$store.commit(SET_NOTIFICATION, {
              title: this.$t("FileMaxSizeTitle"),
              status: false,
              message: this.$t("FileMaxSizeMessage"),
            });
          }
        } else {
          this.company.image_base_64 = null;
        }
      }
    },
  },
};
</script>