<template>
  <b-img class="tooltip-image" fluid :src="getImage()" />
</template>

<style lang="scss" scoped>
.tooltip-image {
  $size: 240px;
  width: $size;
  //height: $size;
}
</style>

<script>
import { BOverlay, BImg } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
export default {
  name: 'product-tooltip',
  props: {
    product: { type: Object, required: true }
  },
  components: {
    BOverlay,
    BImg
  },
  computed: {
    ...mapGetters(['brandLogo'])
  },
  methods: {
    getImage() {
      let tempImages = []
      if (Array.isArray(this.$props.product.product_images)) {
        tempImages = this.$props.product.product_images
      } else {
        tempImages = JSON.parse(this.$props.product.product_images)
      }
      if (tempImages?.length) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${tempImages[0].image_id}`
      }
      return this.brandLogo
    }
  }
}
</script>
