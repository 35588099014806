<template>
  <!--#region Rapor Tanım -->
  <validation-observer ref="phase" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ $t("company_crud.2.title") }}</h5>
        <small class="text-muted">{{ $t("company_crud.2.placeholder") }}</small>
        <div class="next-prev-short">
          <a v-b-tooltip.hover.top="$t('company_crud.prev')" @click="prevTab">
            <feather-icon icon="ArrowLeftIcon" />
          </a>
          <a v-b-tooltip.hover.top="$t('company_crud.next')" @click="nextTab">
            <feather-icon icon="ArrowRightIcon" />
          </a>
        </div>
      </b-col>
      <b-col cols="12">
        <h3 class="mb-2">{{ $t("company_crud.2.invoice") }}</h3>
      </b-col>
      <company-email />
      <company-phone />
      <company-fax />
      <company-country />
      <company-city />
      <company-town />
      <company-post-code />
      <company-line-one />
      <company-line-two />
      <company-line-three />
    </b-row>
  </validation-observer>
  <!--#endregion -->
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { BRow, BCol, VBTooltip } from "bootstrap-vue";
import CompanyEmail from "./Email.vue";
import CompanyPhone from "./Phone.vue";
import CompanyFax from "./Fax.vue";
import CompanyCountry from "./Country.vue";
import CompanyCity from "./City.vue";
import CompanyTown from "./Town.vue";
import CompanyPostCode from "./PostCode.vue";
import CompanyLineOne from "./Line1.vue";
import CompanyLineTwo from "./Line2.vue";
import CompanyLineThree from "./Line3.vue";

export default {
  name: "company-phase-two",
  components: {
    ValidationObserver,
    BRow,
    BCol,

    CompanyEmail,
    CompanyPhone,
    CompanyFax,
    CompanyCountry,
    CompanyCity,
    CompanyTown,
    CompanyPostCode,
    CompanyLineOne,
    CompanyLineTwo,
    CompanyLineThree,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    check() {
      return new Promise((resolve) => {
        this.$refs.phase.validate().then((response) => {
          resolve(response);
        });
      });
    },
    prevTab() {
      this.$root.$emit("prevTab");
    },
    nextTab() {
      this.$root.$emit("nextTab");
    },
  },
};
</script>