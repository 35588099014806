<template>
  <!--#region Ülke -->
  <b-col cols="12" md="3">
    <b-form-group
      :label="$t('address.city')"
      label-for="address-city"
      v-show="companyInvoice.country_id"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('address.city')"
        rules="required"
      >
        <b-overlay
          :show="!companyInvoice.city_ready"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <treeselect
            id="address-city"
            v-model="companyInvoice.city_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="cities"
            :class="errors.length > 0 ? 'is-invalid' : ''"
            @select="citySelect"
          />
        </b-overlay>
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";
import { mapGetters } from "vuex";
import { GET_ALL_TOWNS, SET_TOWNS } from "@/store/services/address-service";

export default {
  name: "company-city",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    Treeselect,
  },
  computed: {
    ...mapGetters(["companyInvoice", "cities"]),
  },
  methods: {
    citySelect(node) {
      return new Promise((resolve) => {
        this.companyInvoice.town_ready = false;
        this.companyInvoice.town_id = undefined;
        this.$store.commit(SET_TOWNS, []);
        if (node.id) {
          this.$store.dispatch(GET_ALL_TOWNS, node.id).then(() => {
            this.companyInvoice.city_ready = true;
            resolve();
          });
        }
        this.companyInvoice.town_ready = true;
        resolve();
      });
    },
  },
  watch: {
    "companyInvoice.country_id"(val) {
      if (val === undefined) {
        this.companyInvoice.town_id = undefined;
      }
    },
  },
};
</script>