<template>
  <!--#region Fiyat Listesi Filtreleri -->
  <b-tr class="multiple-filters">
    <b-td class="product-group-filter" colspan="3">
      <b-form-group
        :label="$t('company_crud.3.product_group')"
        label-for="price-list-category"
      >
        <b-overlay
          :show="!productGroupsReady"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <treeselect
            id="price-list-category"
            v-model="category"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="companyPriceListProductCategories"
            :placeholder="$t('company_crud.3.product_group_placeholder')"
            multiple
          />
        </b-overlay>
      </b-form-group>
    </b-td>
    <b-td class="d-none product-name-area" />
    <b-td colspan="2" class="product-search-area">
      <b-form-group
        :label="$t('transaction.p_name')"
        label-for="price-list-product-name"
      >
        <b-form-input
          v-model="companyPriceListFilters.search"
          id="price-list-product-name"
          :placeholder="$t('company_crud.3.search_placeholder')"
        />
      </b-form-group>
    </b-td>
    <b-td class="product-brand" />
    <b-td class="product-group-filter">
      <b-form-group
        :label="$t('company_crud.3.c_percentagle')"
        label-for="price-list-percentagle"
      >
        <b-input-group>
          <cleave
            id="price-list-percentagle"
            v-model="companyPriceListFilters.percentagle"
            :options="amountOptions"
            class="form-control"
            :disabled="companyPriceListFilters.amount > 0"
            @input="changePercentagle()"
          />
          <b-input-group-append is-text>%</b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-td>
    <b-td class="product-group-filter">
      <b-form-group
        :label="$t('company_crud.3.c_amount')"
        label-for="price-list-amount"
      >
        <cleave
          id="price-list-amount"
          v-model="companyPriceListFilters.amount"
          :options="amountOptions"
          class="form-control"
          :disabled="companyPriceListFilters.percentagle > 0"
          @input="changeAmount()"
        />
      </b-form-group>
    </b-td>
    <b-td class="text-center">
      <b-form-group
        :label="$t('company_crud.3.c_type')"
        label-for="price-list-type"
      >
        <div class="d-flex">
          <b-form-checkbox
            id="price-list-type"
            v-model="companyPriceListFilters.type"
            class="change-type-switch"
            switch
            inline
          />
          <feather-icon
            :class="{
              'text-success': companyPriceListFilters.type,
              'text-danger': !companyPriceListFilters.type,
            }"
            :icon="
              `${
                companyPriceListFilters.type ? 'ChevronsUp' : 'ChevronsDown'
              }Icon`
            "
            size="25"
          />
        </div>
      </b-form-group>
    </b-td>
    <b-td class="text-center" colspan="2">
      <b-button
        variant="primary"
        block
        @click="updatePrices()"
        :disabled="buttonDisabled"
      >
        {{ $t("company_crud.3.update_all_button_text") }}
      </b-button>
      <b-modal
        ref="updateAllModal"
        hide-footer
        centered
        no-close-on-esc
        hide-header-close
        no-close-on-backdrop
        size="md"
        :title="$t('company_crud.3.update_title')"
      >
        <b-card-text class="text-center">
          <i
            class="fas fa-pencil-paintbrush text-success on-update-icon mb-2"
          />
          <br />
          {{
            onUpdateCompleted
              ? $t("company_crud.3.update_message")
              : $t("company_crud.3.on_update_message")
          }}
        </b-card-text>
        <div
          class="d-flex justify-content-center align-items-center"
          v-if="onUpdateCompleted"
        >
          <b-button variant="success" @click="hideModal()">
            {{ $t("company_crud.3.hide_modal") }}
          </b-button>
        </div>
      </b-modal>
    </b-td>
  </b-tr>
  <!--#endregion -->
</template>

<style lang="scss">
.on-update-icon {
  font-size: 7rem;
}
</style>

<script>
import {
  BTr,
  BTd,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormInput,
  BButton,
  BOverlay,
  BModal,
  BCardText,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";
import { mapGetters } from "vuex";
import {
  CHANGE_PRODUCT_PRICES,
  SET_PRICE_LIST_CATEGORY,
} from "@/store/services/priceList-service";

export default {
  name: "company-price-list-filters",
  components: {
    BTr,
    BTd,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BOverlay,
    BModal,
    BCardText,
    Cleave,
    Treeselect,
  },
  data() {
    return {
      onUpdateCompleted: false,
    };
  },
  computed: {
    ...mapGetters([
      "companyPriceListProductCategories",
      "companyPriceListSelectedCategory",
      "companyPriceListFilters",
      "companyPriceListRulePrice",
      "productGroupsReady",
    ]),
    category: {
      get() {
        return this.companyPriceListSelectedCategory;
      },
      set(val) {
        this.$store.commit(SET_PRICE_LIST_CATEGORY, val);
      },
    },
    amountOptions() {
      switch (this.getLocale) {
        case "tr-TR":
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: ",",
            delimiter: ".",
          };
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
            numeralDecimalMark: ".",
            delimiter: ",",
          };
      }
    },
    buttonDisabled() {
      return false
      return (
        this.companyPriceListFilters.percentagle * 1 <= 0 &&
        this.companyPriceListFilters.amount * 1 <= 0
      );
    },
  },
  methods: {
    updatePrices() {
      this.onUpdateCompleted = false;
      this.showModal();
      this.$store.dispatch(CHANGE_PRODUCT_PRICES).then((response) => {
        this.onUpdateCompleted = true;
      });
    },
    showModal() {
      this.$refs.updateAllModal.show();
    },
    hideModal() {
      this.$refs.updateAllModal.hide();
    },
    changePercentagle() {
      if (
        (this.companyPriceListFilters.percentagle === 0 &&
          this.companyPriceListFilters.amount > 0) ||
        (this.companyPriceListFilters.percentagle === 0 &&
          this.companyPriceListFilters.amount === 0)
      ) {
        return;
      }

      if (this.companyPriceListFilters.percentagle === "") {
        this.companyPriceListFilters.percentagle = 0;
        return;
      }

      if (this.companyPriceListFilters.percentagle > 0) {
        this.companyPriceListFilters.amount = 0;
      }

      if (this.companyPriceListFilters.percentagle > 100) {
        this.companyPriceListFilters.percentagle = 100;
      }
    },
    changeAmount() {
      if (
        (this.companyPriceListFilters.amount === 0 &&
          this.companyPriceListFilters.percentagle > 0) ||
        (this.companyPriceListFilters.percentagle === 0 &&
          this.companyPriceListFilters.amount === 0)
      ) {
        return;
      }

      if (this.companyPriceListFilters.amount === "") {
        this.companyPriceListFilters.amount = 0;
        return;
      }

      if (this.companyPriceListFilters.amount > 0) {
        this.companyPriceListFilters.percentagle = 0;
      }
    }
  },
};
</script>
