<template>
  <b-row>
    <b-col cols="12">
      <!-- <form-content /> -->
      <b-overlay
        :show="!ready"
        variant="dark"
        opacity="0.85"
        blur="2px"
        rounded="sm"
      >
        <form-wizard
          ref="wizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          :finish-button-text="$t('company_crud.save')"
          :back-button-text="$t('company_crud.prev')"
          :next-button-text="$t('company_crud.next')"
          class="steps-transparent mb-3"
          @on-complete="saveCompany"
        >
          <tab-content
            ref="tab1"
            :title="$t('company_crud.1.title')"
            icon="feather icon-file-text"
            :before-change="checkPhase1"
          >
            <phase-one ref="p1" />
          </tab-content>
          <tab-content
            :title="$t('company_crud.2.title')"
            icon="feather icon-book"
            :before-change="checkPhase2"
          >
            <phase-two ref="p2" />
          </tab-content>
          <tab-content
            v-if="!isNew"
            :title="$t('company_crud.3.title')"
            icon="feather icon-dollar-sign"
            :before-change="checkPhase3"
          >
            <phase-three ref="p3" />
          </tab-content>
        </form-wizard>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<style lang="scss">
.next-prev-short {
  display: flex;
  position: absolute;
  top: 0;
  right: 20px;
  flex-direction: row;
  gap: 2rem;
  a {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}
</style>

<script>
import { TabContent, FormWizard } from "vue-form-wizard";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";

import { BRow, BCol, BOverlay } from "bootstrap-vue";
import PhaseOne from "./1/PhaseOne.vue";
import PhaseTwo from "./2/PhaseTwo.vue";
import PhaseThree from "./3/PhaseThree.vue";
import {
  GET_COMPANY,
  SAVE_COMPANY,
  SET_DEFAULT_COMPANY,
  UPDATE_COMPANY,
} from "@/store/services/company-service";
import {
  GET_COMPANY_CURRENT_PRICE_LIST,
  SET_DEFAULT_PRICE_LIST,
} from "@/store/services/priceList-service";
import { mapGetters } from "vuex";
import { PUSH_BREADCRUMB } from "@/store/services/breadcrumb-service";

export default {
  name: "company-form-content",
  components: {
    TabContent,
    FormWizard,
    BRow,
    BCol,
    BOverlay,
    PhaseOne,
    PhaseTwo,
    PhaseThree,
  },
  props: {
    isNew: { required: true, type: Boolean },
  },
  data() {
    return {
      ready: false,
    };
  },
  created() {
    this.$store.commit(SET_DEFAULT_COMPANY);
    if (this.isNew) {
      this.$store.commit(SET_DEFAULT_PRICE_LIST);
      this.ready = true;
    } else {
      this.$store
        .dispatch(GET_COMPANY, {
          resolve: false,
          id: this.$route.params.id * 1,
        })
        .then(() => {
          this.$store.commit(PUSH_BREADCRUMB, this.company.company_name);
          this.ready = true;
        });
      this.$store.dispatch(
        GET_COMPANY_CURRENT_PRICE_LIST,
        this.$route.params.id * 1
      );
    }
  },
  beforeDestroy() {
    this.$store.commit(SET_DEFAULT_COMPANY);
    this.$root.$off("nextTab");
    this.$root.$off("prevTab");
  },
  computed: {
    ...mapGetters(["company"]),
  },
  methods: {
    checkPhase1() {
      return new Promise((resolve, reject) => {
        this.$refs.p1.check().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkPhase2() {
      return new Promise((resolve, reject) => {
        this.$refs.p2.check().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkPhase3() {
      return new Promise((resolve, reject) => {
        this.$refs.p3.check().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    saveCompany() {
      if (this.isNew) {
        this.$store.dispatch(SAVE_COMPANY).then((response) => {
          this.$router.push({
            name: "/Company/Display",
            params: { id: response.company_id },
          });
        });
      } else {
        this.$store
          .dispatch(UPDATE_COMPANY, this.$route.params.id * 1)
          .then((response) => {
            this.$router.push({
              name: "/Company/Display",
              params: { id: response.company_id },
            });
          });
      }
    },
  },
  mounted() {
    if (!this.isNew) {
      this.$refs.wizard.activateAll();
    }
    this.$root.$on("nextTab", () => {
      this.$refs.wizard.nextTab();
    });

    this.$root.$on("prevTab", () => {
      this.$refs.wizard.prevTab();
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
